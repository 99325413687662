import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'section9',
  templateUrl: './section9.component.html',
  styleUrls: ['./section9.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section9Component implements OnInit {
  @Input("scrollMagicCtrl") scrollMagicCtrl:any

  constructor() { }

  ngOnInit() {
  }

}
