import { Component, ViewEncapsulation, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';
// import * as ScrollMagic from 'scrollmagic';
@Component({
  selector: 'section2',
  templateUrl: './section2.component.html',
  styleUrls: ['./section2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section2Component {
  @Input("scrollMagicCtrl") scrollMagicCtrl: any
}
