import { Component, OnInit, HostListener, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'section3',
  templateUrl: './section3.component.html',
  styleUrls: ['./section3.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section3Component implements OnInit {
  @Input("scrollMagicCtrl") scrollMagicCtrl:any

  constructor() { }

  ngOnInit() {
  }
}
