import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'section6',
  templateUrl: './section6.component.html',
  styleUrls: ['./section6.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section6Component implements OnInit {
  @Input("scrollMagicCtrl") scrollMagicCtrl:any

  constructor() { }

  ngOnInit() {
  }

}
