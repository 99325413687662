import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'section5',
  templateUrl: './section5.component.html',
  styleUrls: ['./section5.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section5Component implements OnInit {
  @Input("scrollMagicCtrl") scrollMagicCtrl:any

  constructor(
    private langServ: LanguageService
  ) {
   
   }

  ngOnInit() {
  }
  getCulturalImg(): string {
    switch(
      this.langServ.getCurrent()
    ){
      case 'fr' :
      return 'assets/img/cultural-FR.svg'
      case 'en' :
      return 'assets/img/cultural-EN.svg'
    }
  }

}
