import { Component } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { LanguageService } from "./services/language/language.service";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {
  constructor(private meta: Meta, private langServ: LanguageService) {
    this.langServ.onLanguageChanged.subscribe(() => {
      const currentLang = this.langServ.getCurrent();
      localStorage;
      this.meta.removeTag("name='description'");

      if (currentLang == "fr") {
        this.meta.addTags([
          {
            name: "description",
            content:
              "Semioticstudio est un cabinet d’étude indépendant dédié aux études qualitatives et à la sémiologie avec une expertise dans le Luxe. En explorant l’inconscient des marchés, nous délivrons des insights stratégiques et créatifs pour les marques.",
          },
        ]);
      } else {
        this.meta.addTags([
          {
            name: "description",
            content:
              "Semioticstudio is an independent research studio dedicated to qualitative study and semiotics with an expertise in the luxury sector. Thanks to our proprietary research technique, we explore the subconscious of markets to deliver strategic and creative insights for brands.",
          },
        ]);
      }
    });
  }
}
export function appInitializerFactory(langServ: LanguageService) {
  return () => Promise.all([langServ.init().toPromise()]);
}
