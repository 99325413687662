import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section1Component {
  @Input("scrollMagicCtrl") scrollMagicCtrl: any
}
