import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslateService, MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

export function getAppLocale(langServ: LanguageService): string {
  return langServ.getCurrent();
}
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    console.error('missing lang key: ' + params.key + '');
    return params.key;
  }
}
const DEFAULT_LANG = 'fr';
const LANGUAGES = ['fr', 'en'];
/*
  Generated class for the Language provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class LanguageService {
  private currentLanguage;
  onLanguageChanged: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentLanguage)
  public navigatorLanguage = navigator.language.split('-')[0];
  constructor(private translate: TranslateService) {
    console.log('Hello Language Provider');
  }

  init(): Observable<any> {
    if (LANGUAGES.indexOf(this.navigatorLanguage) >= 0) {
      return this.setCurrent(this.navigatorLanguage);
    } else {
      return this.setCurrent(DEFAULT_LANG);
    }
  }

  setCurrent(value): Observable<any> {
    console.log('setCurrent', value);
    this.currentLanguage = value;
    this.translate.setDefaultLang(value);
    return this.translate.use(value).pipe(map(translations => {
      console.log('got translations', translations);
      this.onLanguageChanged.next(this.currentLanguage);
      return translations;
    })
    );
  }

  getCurrent(): string {
    return this.currentLanguage;
  }

  getText(key, params?) {
    if (params && params.count && params.count > 1) {
      key += 'plural';
    }
    return this.translate.instant(key, params);
  }
}
import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Pipe({ name: 'i18n', pure: false})
export class I18nPluralizePipe implements PipeTransform {
  // in my case: the locale will be set from outside

  constructor(private langServ: LanguageService) { }

  public transform(singularKey: string, params: string): Observable<string> {
    return this.langServ.getText(singularKey, params);
  }
}

Injectable();
export class TranslationLoaderService implements TranslateLoader {

  constructor(private http: HttpClient) { }
  getTranslation(lang: string): Observable<any> {
    return this.getAppTranslations(lang);
  }
  getAppTranslations(lang): Observable<any> {
    return this.http.get(`./assets/i18n/${lang}.json`);
  }
}
