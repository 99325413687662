import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
  menuOpen: boolean = false
  constructor(
    private langServ: LanguageService,
    private cDetRef: ChangeDetectorRef
  ) {
  }
  ngOnInit() {
  }
  isMenuOpen(): boolean {
    return this.menuOpen
  }
  toggleBurger() {
    this.menuOpen = !this.menuOpen
  }
  onLanguageChange(lang: string) {
    this.langServ.setCurrent(lang).subscribe(() => {
    })
  }
  isActiveLang(lang:string):boolean{
    return this.langServ.getCurrent()==lang
  }
}
