import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'section4',
  templateUrl: './section4.component.html',
  styleUrls: ['./section4.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section4Component implements OnInit {
  @Input("scrollMagicCtrl") scrollMagicCtrl:any

  constructor() { }

  ngOnInit() {
  }

}
