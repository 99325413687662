import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, LOCALE_ID } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, appInitializerFactory } from "./app.component";
import {
  LanguageService,
  getAppLocale,
  TranslationLoaderService,
  I18nPluralizePipe,
} from "./services/language/language.service";
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AngularFullpageModule } from "@fullpage/angular-fullpage";
import { MainComponent } from "./views/main/main.component";
import { Section1Component } from "./components/sections/section1/section1.component";
import { Section2Component } from "./components/sections/section2/section2.component";
import { Section3Component } from "./components/sections/section3/section3.component";
import { Section4Component } from "./components/sections/section4/section4.component";
import { Section5Component } from "./components/sections/section5/section5.component";
import { Section6Component } from "./components/sections/section6/section6.component";
import { Section7Component } from "./components/sections/section7/section7.component";
import { Section8Component } from "./components/sections/section8/section8.component";
import { Section9Component } from "./components/sections/section9/section9.component";
import { Section10Component } from "./components/sections/section10/section10.component";
import { Section11Component } from "./components/sections/section11/section11.component";
import { Section12Component } from "./components/sections/section12/section12.component";
import { MenuComponent } from "./components/menu/menu.component";

import { FooterComponent } from "./components/footer/footer.component";

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    Section1Component,
    Section2Component,
    Section3Component,
    Section4Component,
    Section5Component,
    Section6Component,
    Section7Component,
    Section8Component,
    Section9Component,
    Section10Component,
    Section11Component,
    Section12Component,
    MenuComponent,
    FooterComponent,
    I18nPluralizePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService,
        deps: [HttpClient],
      },
    }),
    AngularFullpageModule,
  ],
  providers: [
    TranslateService,
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LanguageService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: getAppLocale,
      deps: [LanguageService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
