import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'section10',
  templateUrl: './section10.component.html',
  styleUrls: ['./section10.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class Section10Component implements OnInit {
  @Input("scrollMagicCtrl") scrollMagicCtrl:any

  constructor() { }

  ngOnInit() {
  }

}
