import {
  Component,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import { LanguageService } from "src/app/services/language/language.service";
declare const ScrollMagic;
declare const $;
declare const TweenMax;
declare const Power0;
declare const Power1;
declare const Power4;
declare const TimelineMax;
declare const Linear;

@Component({
  selector: "main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements AfterViewInit, OnInit {
  scrollMagicCtrl: any;
  constructor(
    private langServ: LanguageService,
    private cDetRef: ChangeDetectorRef
  ) {}
  getLang(): string {
    return this.langServ.getCurrent();
  }

  ngOnInit() {
    this.langServ.onLanguageChanged.subscribe(() => {
      this.cDetRef.detectChanges();
      console.log("updating main view lang", this.langServ.getCurrent());
    });
  }
  ngAfterViewInit() {
    function onResize() {
      $(".middle").css({
        transform: "translateX(-50%)",
        "-moz-transform": "translateX(-50%)",
        "-webkit-transform": "translateX(-50%)",
        "-o-transform": "translateX(-50%)",
        "-ms-transform": "translateX(-50%)",
      });
    }

    var controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: "onLeave",
      },
    });
    controller.scrollTo(function (e) {
      TweenMax.to(window, 1, {
        scrollTo: {
          y: e,
          autoKill: !0,
        },
        ease: Power1.easeInOut,
      });
    });
    var currentStep = null;
    $(window).resize(function () {
      onResize();
    }),
      $(function () {
        $(".burger-menu").click(function () {
          $(".menu").toggleClass("menu-on"),
            $(this).toggleClass("menu-on"),
            $(".selection").toggleClass("selection-in");
        }),
          $(".trigger").click(function (e) {
            var a = $(this).attr("data-target"),
              t = "#" + a;
            controller.scrollTo(t);
            currentStep = $(t).find("[class^=step]:first");
            console.log(currentStep);
            $(".selection").toggleClass("selection-in");
            $(".menu").toggleClass("menu-on");
            $(".burger-menu").toggleClass("menu-on");
          }),
          $(".down-arrow").click(function () {
            console.log("currentStep", currentStep);
            var currentStepClassId = parseInt(
              $(currentStep).attr("class").match(/step./)[0].replace("step", "")
            );
            var nextStep = $(".step" + (currentStepClassId + 1));
            var nextStepY = nextStep.offset().top;
            console.log("nextStep", nextStep, nextStepY);
            window.scroll(0, nextStepY);
          });
        var e = ($("#parallax1").offset(), $(".section").height()),
          a = $(".inner-principal-1").height();
        $(".robust").css({
          height: a,
        });
        var t = 4 * e;

        var n = new TimelineMax();
        n.set($(".dots .dot1"), {
          className: "+= is-active",
        })
          .to("#parralax1 .inner-principal-1", 1, {
            height: 0,
            ease: Power0.easeInOut,
          })

          .set($(".dots .dot2"), {
            className: "+= is-active",
          })
          .to("#parralax1 .inner-principal-2", 1, {
            height: 0,
            ease: Power0.easeInOut,
          })

          .set($(".dots .dot3"), {
            className: "+= is-active",
          })
          .to("#parralax1 .inner-principal-3", 1, {
            height: 0,
            ease: Power0.easeInOut,
          })

          .set($(".dots .dot4"), {
            className: "+= is-active",
          })
          .to("#parralax1 .inner-principal-4", 1, {
            height: 0,
            ease: Power0.easeInOut,
          })

          .set($(".dots .dot5"), {
            className: "+= is-active",
          })
          .to("#parralax1 .inner-principal-5", 1, {
            height: 0,
            ease: Power0.easeInOut,
          })

          .set($(".dots .dot6"), {
            className: "+= is-active",
          })
          .to("#parralax1 .inner-principal-6", 1, {
            height: 0,
            ease: Power0.easeInOut,
          })

          .set($(".dots .dot7"), {
            className: "+= is-active",
          });
        var c = new TimelineMax();
        var circlesFive = TweenMax.staggerTo(
          ".circle-five",
          t / 3,
          { className: "+=full-opacity" },
          t / 3
        );

        var g =
          (new ScrollMagic.Scene({
            triggerElement: "#trigger1",
            duration: 2 * e,
          }).addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger1",
            duration: e / 2,
          })
            .setPin("#parralax0")
            .addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger3",
            duration: t,
          })
            .setPin("#parralax1")
            .setTween(n)
            .addTo(controller),
          new TimelineMax());
        g.set($(".dots .dot1"), {
          className: "+= is-active",
        })
          .to("#parralax-torture .torture1", 0.3, {
            yPercent: -50,
            autoAlpha: 0,
            ease: Power4.easeInOut,
          })
          .set($(".dot"), {
            className: "-= is-active",
          })
          .set($(".dots .dot2"), {
            className: "+= is-active",
          })
          .fromTo(
            "#parralax-torture .torture2",
            1,
            {
              yPercent: 45,
              autoAlpha: 0,
            },
            {
              yPercent: 0,
              autoAlpha: 1,
              ease: Power4.easeInOut,
            }
          )
          .to("#parralax-torture .torture2", 0.3, {
            yPercent: -50,
            autoAlpha: 0,
            ease: Power4.easeInOut,
          })
          .set($(".dot"), {
            className: "-= is-active",
          })
          .set($(".dots .dot3"), {
            className: "+= is-active",
          })
          .fromTo(
            "#parralax-torture .torture3",
            1,
            {
              yPercent: 45,
              autoAlpha: 0,
            },
            {
              yPercent: 0,
              autoAlpha: 1,
              ease: Power4.easeInOut,
            }
          )
          .to("#parralax-torture .torture3", 0.3, {
            yPercent: -50,
            autoAlpha: 0,
            ease: Power4.easeInOut,
          })
          .set($(".dot"), {
            className: "-= is-active",
          })
          .set($(".dots .dot4"), {
            className: "+= is-active",
          })
          .fromTo(
            "#parralax-torture .torture4",
            1,
            {
              yPercent: 45,
              autoAlpha: 0,
            },
            {
              yPercent: 0,
              autoAlpha: 1,
              ease: Power4.easeInOut,
            }
          )
          .to("#parralax-torture .torture4", 0.3, {
            yPercent: -50,
            autoAlpha: 0,
            ease: Power4.easeInOut,
          })
          .set($(".dot"), {
            className: "-= is-active",
          })
          .set($(".dots .dot5"), {
            className: "+= is-active",
          })
          .fromTo(
            "#parralax-torture .torture5",
            1,
            {
              yPercent: 45,
              autoAlpha: 0,
            },
            {
              yPercent: 0,
              autoAlpha: 1,
              ease: Power4.easeInOut,
            }
          );
        new ScrollMagic.Scene({
          triggerElement: "#trigger7",
          duration: "300%",
        })
          .setPin("#parralax-torture")
          .addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger7",
            duration: "300%",
          })
            .setTween(g)
            .addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger7",
            duration: "300%",
            offset: "10%",
          })
            .setTween(c)
            .addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger5",
            duration: t,
          })
            .setPin("#why-pin")
            .addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger5",
            duration: t / 2,
            offset: 100,
          })
            .setTween(circlesFive)
            .addTo(controller),
          new ScrollMagic.Scene({
            triggerElement: "#trigger2",
            duration: e,
          })
            .setPin("#chart-pin")
            .addTo(
              controller,
              new ScrollMagic.Scene({
                triggerElement: "#trigger11",
                duration: e / 2,
              })
            )
            .addTo(controller);

        var wipeAnimation = new TimelineMax()
          .fromTo(
            "section.panel.panel2",
            1,
            { x: "100%" },
            { x: "0%", ease: Linear.easeNone }
          )
          .fromTo(
            "section.panel.panel3",
            1,
            { x: "100%" },
            { x: "0%", ease: Linear.easeNone }
          )
          .fromTo(
            "section.panel.panel4",
            1,
            { x: "100%" },
            { x: "0%", ease: Linear.easeNone }
          )
          .fromTo(
            "section.panel.panel5",
            1,
            { x: "100%" },
            { x: "0%", ease: Linear.easeNone }
          );

        // create scene to pin and link animation

        if (window.innerWidth > 576) {
          new ScrollMagic.Scene({
            triggerElement: "#trigger12",
            triggerHook: "onLeave",
            duration: "300%",
          })
            .setPin("#trigger12")
            .setTween(wipeAnimation)
            .addTo(controller);
        }
      });
  }
}
